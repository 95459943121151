import React from 'react';
import styled from 'styled-components';
import SpinnerSvg from 'assets/icons/circle-loader.svg';
import MgSpinner from 'assets/icons/mg/spinner.svg';
import theme from 'utils/theme';

interface ILoader {
  height?: number;
  width?: number;
  wrapperHeight?: string;
}
export const Loader: React.FC<ILoader> = ({
  height = 80,
  width = 80,
  wrapperHeight = '30vh',
}) => (
  <OuterWrapper height={wrapperHeight}>
    <LoaderWrapper height={height} width={width}>
      <MgSpinner />
    </LoaderWrapper>
  </OuterWrapper>
);

const OuterWrapper = styled.div<{ height: string }>`
  display: flex;
  height: ${({ height }) => height};
  align-items: center;
  justify-content: center;
`;

const LoaderWrapper = styled(MgSpinner)<{
  color: string;
  height: number;
  width: number;
}>`
  height: ${({ height }) => height + 'px'};
  width: ${({ width }) => width + 'px'};
`;
