import {
  LOCALISED_COUNTRIES,
  LOCALISED_EU_CONTRIES,
} from 'state/user/constants';
import { floor } from 'lodash';
import { DurationType, Product, Subscription } from 'types/product';
import { handleError } from './error';

interface Trial {
  amount: number;
  currency?: string;
  duration?: number;
  duration_type?: string;
  locale?: string;
  old_amount?: number;
  sku?: string;
}

const getCurrency = (currencyId: string) => {
  switch (currencyId) {
    default:
      return '$';
    case 'GBP':
      return '£';
    case 'EUR':
      return '€';
    case 'USD':
      return '$';
  }
};

const getDailyPrice = (months: number, price: number) => {
  const daysInAMonth = 30;

  return price / (daysInAMonth * months);
};

const getWeeklyPrice = (months: number, price: number) => {
  const weeksInAMonth = 4;

  return price / (weeksInAMonth * months);
};

const getMontlyPrice = (months: number, price: number) => price / months;

const getMonths = (type: DurationType, duration: number) => {
  if (type === 'months') {
    return duration;
  }
  if (type === 'years') {
    return duration * 12;
  }

  return 0;
};

const getDailyTrialPrice = (
  amount?: number,
  duration?: number,
  durationType?: string,
): number => {
  const WEEK_LENGTH = 7;
  const MONTH_LENGTH = 30;

  if (!amount || !duration || !durationType) {
    return 0;
  }

  if (durationType === 'weeks') {
    return amount / (duration * WEEK_LENGTH);
  }

  if (durationType === 'months') {
    return amount / (duration * MONTH_LENGTH);
  }

  return 0;
};

export const getCurrencyFromLocale = (locale: string) => {
  switch (locale) {
    case 'EU':
      return 'EUR';
    case 'EN_US':
      return 'USD';
    case 'GB':
      return 'GBP';
    case 'CA':
      return 'CAD';
    case 'AU':
      return 'AUD';
    case 'NZ':
      return 'NZD';
    case 'MX':
      return 'MXN';
    default:
      return 'USD';
  }
};

export const normalizeStates = (isoCountry?: string, isoState?: string) => {
  if (isoCountry?.toLowerCase() === 'us') {
    return isoState?.toLowerCase() || null;
  }
  return null;
};

export const getValidatedIsoCountry = (isoCountry: string) =>
  LOCALISED_EU_CONTRIES.includes(isoCountry)
    ? 'EU'
    : LOCALISED_COUNTRIES.includes(isoCountry)
    ? isoCountry.toUpperCase() === 'US'
      ? 'EN_US'
      : isoCountry
    : 'EN';

export const getLocalisedIsoCountry = () => {
  if (typeof window === 'undefined') return 'EN';
  // return new URLSearchParams(window.location.search).get('locale') || 'EN';
  return (
    localStorage.getItem('userLocale') ||
    new URLSearchParams(window.location.search).get('locale') ||
    'EN'
  );
};

export const getLocalisedProduct = (product: Product): Subscription | null => {
  try {
    const name = product?.name || '';

    const matchingIndex =
      product?.prices?.findIndex(
        price =>
          price?.locale?.toLowerCase() ===
          getLocalisedIsoCountry()?.toLowerCase(),
      ) || 0;
    const priceIndex = matchingIndex === -1 ? 0 : matchingIndex;
    const regularPrice = product?.prices?.[priceIndex].price || 0;
    const discount = (product?.prices?.[priceIndex].discount || 0).toFixed(2);
    const finalPrice = product?.prices?.[priceIndex].final_price || 0;
    const savings = (
      (product?.prices?.[priceIndex].discount_percent || 0) * 100
    ).toFixed(0);
    const currencyId = product?.prices?.[priceIndex].currency_id;
    const currency = getCurrency(currencyId);
    const months = getMonths(
      product?.plan?.type || 'months',
      product?.plan?.duration || parseInt(name?.match(/\d+/)?.[0] || '1'),
    );
    const regularDailyPrice =
      floor(getDailyPrice(months, regularPrice), 2) || 0;
    const discountedDailyPrice =
      floor(getDailyPrice(months, finalPrice), 2) || 0;
    const regularWeeklyPrice =
      floor(getWeeklyPrice(months, regularPrice), 2) || 0;
    const discountedWeeklyPrice =
      floor(getWeeklyPrice(months, finalPrice), 2) || 0;

    const regularMontlyPrice =
      floor(getMontlyPrice(months, regularPrice), 2) || 0;
    const discountedMontlyPrice =
      floor(getMontlyPrice(months, finalPrice), 2) || 0;

    const sku = product?.sku || '';

    const duration = product?.plan?.duration;
    const durationType = product?.plan?.type;

    const hasTrials = product?.has_trials;

    let trialAmount, trialOldAmount, trialDailyPrice;

    if (hasTrials) {
      const localisedTrial: Trial | undefined = product.trials?.find(item => {
        const trialItem = item as Trial;
        return (
          trialItem.locale?.toLowerCase() ===
          getLocalisedIsoCountry().toLowerCase()
        );
      });

      trialAmount = localisedTrial?.amount;
      trialOldAmount = localisedTrial?.old_amount;
      trialDailyPrice = getDailyTrialPrice(
        localisedTrial?.amount,
        localisedTrial?.duration,
        localisedTrial?.duration_type,
      ).toFixed(2);
    }

    return {
      regularPrice: regularPrice.toFixed(2),
      discount,
      finalPrice: finalPrice.toFixed(2),
      savings,
      currency,
      currencyId,
      regularDailyPrice,
      discountedDailyPrice,
      regularWeeklyPrice,
      discountedWeeklyPrice,
      regularMontlyPrice,
      discountedMontlyPrice,
      months,
      name,
      sku,
      key: product?.key || '',
      description: product?.description || '',
      duration,
      durationType,
      hasTrials,
      ...(hasTrials && {
        trialAmount,
        trialOldAmount,
        trialDailyPrice,
      }),
    };
  } catch (e) {
    handleError(e);

    return null;
  }
};
