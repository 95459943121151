import React, { ComponentPropsWithoutRef } from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import ButtonContainer from 'components/ButtonContainer';
import Text from 'components/Text';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import CheckSvg from 'assets/icons/check.svg';
import ButtonArrow from '../assets/icons/formkit_right.svg';
import CheckBox from 'components/CheckBox';
import quizSvgs from 'utils/constants/quizSvgs';
import SingleIcon from '../assets/icons/single-icon.svg';

interface QuizAnswerButtonProps extends ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  active?: boolean;
  children: string;
  ImageComponent?: ReactNode;
  maxSelected?: boolean;
  hideCheckIcon?: boolean;
  isRound?: boolean;
  emoji: string;
}

const Container = styled(ButtonContainer)<{
  active?: boolean;
  maxSelected?: boolean;
  $isWithCheckIcon?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  border: 1px solid
    ${({ active, theme }) =>
      active ? theme.colors.mgSecondary100 : theme.colors.lighterGrey};
  cursor: ${({ maxSelected }) => (maxSelected ? 'auto' : 'cursor')};
  border-radius: 1rem;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 1.25rem 1rem;
  background: ${({ active, theme }) =>
    active ? theme.colors.mgSecondary100 : theme.colors.white};
  ${({ active }) =>
    !active && 'box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);'}
  opacity: ${({ maxSelected }) => (maxSelected ? '0.5' : '1')};
  height: 4.5rem;

  @media ${tablet} {
    width: unset;
    height: 4rem;
  }
`;

const RightArrow = styled(ButtonArrow)<{ active?: boolean }>`
  & path {
    fill: ${({ theme, active }) => (active ? '#FFF' : 'initial')};
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 80px;
  max-height: 64px;
`;

const ButtonText = styled(Text)<{ active?: boolean }>`
  color: #000;
  font-family: 'Fustat', sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem;
  text-align: left;
  max-width: 22.5rem;

  @media ${tablet} {
    /* font-size: 1rem; */
    line-height: 1.5rem;
    max-width: 17rem;
  }
`;

const CheckIcon = styled(CheckSvg)`
  // position: absolute;
  // right: 12px;
  // top: 50%;
  // transform: translateY(-50%);
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.colors.light0};
`;

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 0.5rem;
  @media ${tablet} {
    gap: 8px;
  }
`;

const Emoji = styled.div`
  width: 2.125rem;
  height: 2.125rem;

  /* @media ${tablet} {
    width: 2rem;
    height: 2rem;
  } */

  & svg {
    height: 2.125rem;
    width: 2.125rem;

    /* @media ${tablet} {
      width: 2rem;
      height: 2rem;
    } */
  }
`;

const StyledSingleIcon = styled(SingleIcon)<{ active?: boolean }>`
  & path {
    stroke: ${({ active, theme }) =>
      active ? theme.colors.black : theme.colors.lightGrey};
  }
`;

const QuizAnswerButton: FC<QuizAnswerButtonProps> = ({
  onClick,
  active,
  children,
  ImageComponent,
  maxSelected,
  emoji,
  isRound = false,
  hideCheckIcon = false,
  ...props
}) => {
  const { isMobile } = useQuery();

  const renderSvgImage = (svgKey: string) => {
    const Svg = quizSvgs[svgKey];
    return (
      <Emoji>
        <Svg />
      </Emoji>
    );
  };
  return (
    <Container
      onClick={onClick}
      active={active}
      $isWithCheckIcon={active && !hideCheckIcon}
      maxSelected={maxSelected}
      as="button"
      {...props}
    >
      <CheckContainer>
        {emoji && renderSvgImage(emoji)}
        <ButtonText active={active}>{children}</ButtonText>
      </CheckContainer>
      {!hideCheckIcon ? (
        <CheckBox checked={active} quizVariant isRound={isRound} />
      ) : (
        <StyledSingleIcon active={active} />
      )}
    </Container>
  );
};

export default React.memo(QuizAnswerButton);
